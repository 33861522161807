<template>
    <v-container class="ma-0 pa-0">
        <v-row
            class="d-flex flex-row flex-nowrap items-center justify-space-between w-100 ma-0 pa-0"
            align-content="center"
            justify="space-between"
        >
            <v-col class="ma-0 pa-0 d-flex align-center justify-start">
                <v-card
                    elevation="0"
                    rounded="lg"
                    class="pa-3 py-5 d-inline-flex justify-center align-center"
                >
                    <SimpleButton
                        icon="mdi-clipboard-check-multiple"
                        :text="$t('evaluate.actions.lecture.assign')"
                        @click="handleAssignLecture"
                    />
                </v-card>
            </v-col>
            <v-col class="ma-0 pa-0 pl-3 d-flex justify-end" >
                <v-card color="white" elevation="0" class="pa-3 py-5 d-inline-flex" rounded="lg">
                    <div class="mr-3">
                        <SimpleSelect
                            :items="evaluationStateOptions"
                            :value="evaluationStateOptions"
                            :model="currentEvaluationState"
                            :placeholder="$t('evaluate.inputs.placeholders.chooseEvaluationState')"
                            defaultItemValueField="id"
                            defaultItemNameField="name"
                            innerIcon="mdi-layers"
                            @new-value-selected="(id) => (currentEvaluationState = id)"
                            withLabel
                            :label="$t('evaluate.inputs.labels.state')"
                        />
                    </div>
                    <div class="d-inline-flex mr-3">
                        <SimpleSelect
                            :items="courseOptions"
                            :value="currentCourse"
                            :model="currentCourse"
                            :placeholder="$t('diagnoses.inputs.placeholders.chooseCourse')"
                            defaultItemValueField="id"
                            defaultItemNameField="name"
                            innerIcon="mdi-folder"
                            @new-value-selected="(id) => (currentCourse = id)"
                            withLabel
                            :label="$t('diagnoses.inputs.labels.course')"
                        />
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('evaluate.titles.listOfAssignedLectures') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <AssignedEvaluationsTable
                    :headers="assignedEvaluationsHeaders"
                    :items="assignedEvaluationsItems"
                    @on-update="onUpdateEvaluation"
                    @on-stats="onStatsEvaluation"
                    @on-delete="onDeleteEvaluation"
                    @on-evaluation="onEvaluate"
                    showDetail
                    showEvaluate
                    :loading="loading"
                />
            </section>
        </v-card>
    </v-container>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import AssignedEvaluationsTable from '../shared/tables/AssignedEvaluationsTable.vue'
export default {
    name: 'AssignmentAndLectures',
    components: {
        SimpleButton,
        SimpleSelect,
        AssignedEvaluationsTable
    },
    data() {
        return {
            currentCourse: 0,
            courseOptions: [],
            currentEvaluationState: 'ALL',
            evaluationID: null,
            loading: false,
            updateEvaluation: false,
            successUpdate: false,
            selectedEvaluation: undefined,
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            diagnosesFilters: (state) => state.fluency.filters,
            assignedEvaluations: (state) => state.fluency.assignedEvaluations,
            userID: (state) => state.userData.user.id,
        }),
        ...mapGetters({
            evaluationDetail: 'fluency/currentDiagnostic',
        }),
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedText'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedTo'),
                    align: 'center',
                    sortable: true,
                    value: 'course_name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'status',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.startDate'),
                    align: 'center',
                    sortable: true,
                    value: 'created_at',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        assignedEvaluationsItems() {
            let filteredEvaluations = this.assignedEvaluations

            if(this.currentCourse !== 0) {
                filteredEvaluations = filteredEvaluations.filter(e => e.course_id === this.currentCourse)
            }

            if(this.currentEvaluationState !== 'ALL') {
                filteredEvaluations = filteredEvaluations.filter((e) => e.evaluation_state === this.currentEvaluationState)
            }

            return filteredEvaluations
        },
        evaluationStateOptions() {
            return [
                {
                    id: 'ALL',
                    name: this.$t('evaluate.inputs.filters.evaluationState.all'),
                },
                {
                    id: 'NO_INITIATED',
                    name: this.$t('evaluate.evaluations.states.notInitiated'),
                },
                {
                    id: 'IN_PROGRESS',
                    name: this.$t('evaluate.evaluations.states.inProgress'),
                },
                {
                    id: 'COMPLETED',
                    name: this.$t('evaluate.evaluations.states.completed'),
                }
            ]
        },
    },
    methods: {
        ...mapMutations('fluency', ['setCourseSelected', 'setDiagnosticEvaluationType', 'setCurrentCourseResultsID', 'setCurrentCourseTextResultsID']),
        ...mapActions('fluency', ['getDiagnosisDetail', 'getAssignedEvaluations', 'deleteEvaluationAssignment']),

        handleAssignLecture() {
            this.$router.push('/teacher/evaluations/assign')
        },
        async onUpdateEvaluation(evaluation) {
            this.selectedEvaluation = evaluation
            this.updateEvaluation = true
        },
        closeUpdateModal() {
            this.updateEvaluation = false
            this.selectedEvaluation = null
        },
        onStatsEvaluation(evaluation) {
            this.setCurrentCourseResultsID({courseID: evaluation.course_id})
            this.setCurrentCourseTextResultsID({textID: evaluation.text_id})
            return this.$router.push(`/teacher/evaluations/results?evaluationId=${evaluation.id}`)
        },
        onEvaluate(evaluationID) {
            this.$router.push(`/teacher/evaluations/evaluate/${evaluationID}`)
        },
        async onDeleteEvaluation(evaluation) {
            try {
                this.loading = true
                await this.deleteEvaluationAssignment({ evaluationID: evaluation.id })
            } catch (error) {
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        handleGoBackToAssignedEvaluations() {
            this.$router.push('/teacher/diagnoses/assigned')
        },
    },
    watch: {
        async currentCourse(courseID) {
            this.setCourseSelected({ courseID })
        },
    },
    async beforeMount() {
        this.loading = true

        await this.getAssignedEvaluations({ teacherID: this.userID, courseID: this.currentCourse })

        const courses = this.courses
            .filter((course) => course.grade !== 'G0')
            .sort((a, b) => {
                if (a.ordinalGrade < b.ordinalGrade) return -1
                else if (a.ordinalGrade > b.ordinalGrade) return 1
                else {
                    // grades are equal, compare by section
                    if (a.section < b.section) return -1
                    else if (a.section > b.section) return 1
                    else return 0
                }
            })

        const coursesFiltered = courses.filter(c => c.id === this.assignedEvaluations.find(e => e.course_id === c.id)?.course_id)

        this.courseOptions = [
            {
                id: 0,
                name: this.$t('evaluate.inputs.filters.course.showAll'),
            },
            ...coursesFiltered]

        this.loading = false
    },
}
</script>
